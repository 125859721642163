export const defaultProjects = {
    list: [
        {
            id: 1,
            name: 'Artery',
            nameCode: 'EYWA ',
            logo: 'https://artpad.site/public/img/projects/eywa.png',
            status: 'upcoming',
            round: 'private',
            description: 'Some text',
            total: 57500,
            start: Math.floor(new Date().getTime() / 1000),
            price: 0.069,
            registration: 1676761200,
            busd: 0,
            done: 0,
            goal: 833333,
            listing: Math.floor(new Date().getTime() / 1000 + 7 * 24 * 60 * 60),
        },
    ],
    all: 1,
    upcoming: 1,
    active: 0,
    ended: 0,
    vesting: 0,
};

export const chechInit = (obj) => {
    if (!obj.hasOwnProperty('list')) {
        obj.list = defaultProjects.list;
    } else {
        let newList = [];
        obj.list.forEach((el) => {
            el = Object.assign({}, defaultProjects.list[0], el);
            newList.push(el);
        });
        obj.list = newList;
    }
    if (!obj.hasOwnProperty('all')) {
        obj.all = 1;
    }
    if (!obj.hasOwnProperty('upcoming')) {
        obj.upcoming = 1;
    }
    if (!obj.hasOwnProperty('ended')) {
        obj.ended = 0;
    }
    if (!obj.hasOwnProperty('vesting')) {
        obj.vesting = 0;
    }
};
