import axios from 'axios';
import { getCookie } from '../../helpers/getCookie';

export const getMarcetCup = async () => {
    let domain = document.domain;
    if (domain === 'localhost') {
        domain = 'artpad.site';
    }
    let FDMC = getCookie('FDMC');
    let MC = getCookie('MC');
    let PRICEMC = getCookie('PRICEMC');
    if (FDMC && MC && PRICEMC) {
        return { FDMC: FDMC, MC: MC, PRICEMC: PRICEMC };
    }
    try {
        const { data } = await axios.get(`https://${domain}/api.php?parser=1`);
        FDMC = data.data.ARTR[0].quote.USD.fully_diluted_market_cap;
        MC = data.data.ARTR[0].quote.USD.market_cap;
        PRICEMC = data.data.ARTR[0].quote.USD.price;
        document.cookie = 'FDMC=' + FDMC + '; max-age=3600';
        document.cookie = 'MC=' + MC + '; max-age=3600';
        document.cookie = 'PRICEMC=' + PRICEMC + '; max-age=3600';
        return { FDMC: FDMC, MC: MC, PRICEMC: PRICEMC };
    } catch (e) {
        console.log(e, 'eeoo');
        return { FDMC: 0, MC: 0, PRICEMC: 0 };
    }
};
