import classes from './styles.module.scss';
import lang from './local.json';

import InfoIcon from '../../components/Icons/info';
import Table from '../../components/Table';
import getLocale from '../../helpers/getLoacale';

const {
    levels,
    levels__top,
    levels__block,
    levels__blockTitle,
    levels__blockTitleText,
    levels__blockTitleToolTip,
    levels__blockText,
    levels__blockText_accent,
    levels__header,
    levels__table,

    levels__toolTipText,
} = classes;

// const { numbersOfSteakers, totalARTR, apy } = window.initState?.levels_page_today
const initState = window.initState?.levels_page_today
? window.initState.levels_page_today
    : {
        main_header: {
            number_of_stakers : 0,
            total_artr_staked : 0,
            apy : 0.00,
            apy_info :"APY info"
        },
        main_block_1: {
            name_1 : "ARTR LEVELS",
            text_1 : "Dear investor!<br/>Pay your attention that SPORTZCHAIN token (SPN) deposit is available on several blockchain networks: BNB Chain, Polygon.<br/>The distribution of SPORTZCHAIN token will carried out in polygon only!!",
            "table_1" : {
                "th_header" : {
                    "th_1" : "№ TIER",
                    "th_2" : "STAKING",
                        "th_2_info" : "Column info",
                    "th_3" : "MULTIPLIER",
                        "th_3_info" : "Column info",
                    "th_4" : "ALLOCATION TYPE",
                        "th_4_info" : "Column info",
                },

                "td_1" : {
                    "1" : 1,
                    "2" : "5000 wARTR",
                    "3" : "X1",
                    "4" : "Lottery (25%)"
                },


                "td_2" : {
                    "1" : 2,
                    "2" : "10000 wARTR",
                    "3" : "X1",
                    "4" : "Lottery (50%)"
                },

                "td_3" : {
                    "1" : 3,
                    "2" : "14000 wARTR",
                    "3" : "X1",
                    "4" : "Lottery (75%)"
                },

                "td_4" : {
                    "1" : 4,
                    "2" : "20000 wARTR",
                    "3" : "X1",
                    "4" : "Guaranteed"
                },

                "td_5" : {
                    "1" : 5,
                    "2" : "50000 wARTR",
                    "3" : "X2.5",
                    "4" : "Guaranteed"
                },

                "td_6" : {
                    "1" : 6,
                    "2" : "100000 wARTR",
                    "3" : "X5",
                    "4" : "Guaranteed"
                },

                "td_7" : {
                    "1" : 7,
                    "2" : "200000 wARTR",
                    "3" : "X11",
                    "4" : "Guaranteed"
                },

                "td_8" : {
                    "1" : 8,
                    "2" : "500000 wARTR",
                    "3" : "X28",
                    "4" : "Guaranteed"
                },

                "td_9" : {
                    "1" : 9,
                    "2" : "1000000 wARTR",
                    "3" : "X36", 
                    "4" : "Guaranteed"
                }

            }
        },
        "main_block_2" : {
            "name_2" : "STAKING CONDITIONS",
            "text_2" : "Dear investor! <br/>Pay your attention that SPORTZCHAIN token (SPN) deposit is available on several blockchain networks: BNB Chain, Polygon.<br/>The distribution of SPORTZCHAIN token will carried out in polygon only!!",
            "table_2" : {
                "th_header" : {
                    "th_1" : "STAKING PERIOD",
                    "th_2" : "STAKING",
                    "th_3" : "APY",
                        "th_3_info" : "Column info",
                    "th_4" : "MULTIPLIER",
                        "th_4_info" : "Column info",
                },

                "td_1" : {
                    "1" : "1 month",
                    "2" : "wARTR",
                    "3" : "0 %",
                    "4" : "X1"
                },


                "td_2" : {
                    "1" : "4 month",
                    "2" : "wARTR",
                    "3" : "12 %",
                    "4" : "X2"
                },

                "td_3" : {
                    "1" : "6 month",
                    "2" : "wARTR",
                    "3" : "18%",
                    "4" : "X3"
                },
                "td_4" : {
                    "1" : "12 month",
                    "2" : "wARTR",
                    "3" : "24%",
                    "4" : "X3"
                }

            }
        },
        "main_block_3" : {
            "text_3" : "Dear investor! <br/>Pay your attention that SPORTZCHAIN token (SPN) deposit is available on several blockchain networks: BNB Chain, Polygon.<br/>The distribution of SPORTZCHAIN token will carried out in polygon only!!"
        },
        
        "main_block_4" : {
            "name_4" : "REWARD WITH BONUS СOINS",
            "text_4" : "Dear investor! <br/>Pay your attention that SPORTZCHAIN token (SPN) deposit is available on several blockchain networks: BNB Chain, Polygon. <br/>The distribution of SPORTZCHAIN token will carried out in polygon only!!",
            "table_4" : {
                "th_header" : {
                    "th_1" : "1 MONTH",
                    "th_2" : "4 MONTH",
                    "th_3" : "6 MONTH",
                    "th_4" : "12 MONTH"
                },

                "td_1" : {
                    "1" : "0 %",
                    "2" : "12 %",
                    "3" : "18 %",
                    "4" : "24 %"
                }

            }
        },
        "main_block_5" : {
            "text_5" : "Dear investor! <br/>Pay your attention that SPORTZCHAIN token (SPN) deposit is available on several blockchain networks: BNB Chain, Polygon. <br/>The distribution of SPORTZCHAIN token will carried out in polygon only!!"
        }    
      };

const LevelsPage = ({ setModals = () => {}, setToolTip = () => {} }) => {
    const locale = getLocale();

    const table1Rows = [
        {
            cells: [
                {
                    value: '1', 
                    type: 'text',
                },
                {
                    value: '5000 wARTR ',
                    type: 'text',
                },
                {
                    value: 'X1',
                    type: 'text',
                },
                {
                    value: lang.lottery[locale] + ' (25%)',
                    type: 'text',
                },
            ],
            child: [],
        },
        {
            cells: [
                {
                    value: '2',
                    type: 'text',
                },
                {
                    value: '10000 wARTR ',
                    type: 'text',
                },
                {
                    value: 'X1',
                    type: 'text',
                },
                {
                    value: lang.lottery[locale] + ' (50%)',
                    type: 'text',
                },
            ],
            child: [],
        },
        {
            cells: [
                {
                    value: '3',
                    type: 'text',
                },
                {
                    value: '14000 wARTR ',
                    type: 'text',
                },
                {
                    value: 'X1',
                    type: 'text',
                },
                {
                    value: lang.lottery[locale] + ' (75%)',
                    type: 'text',
                },
            ],
            child: [],
        },
        {
            cells: [
                {
                    value: '4',
                    type: 'text',
                },
                {
                    value: '20 000 wARTR ',
                    type: 'text',
                },
                {
                    value: 'X1',
                    type: 'text',
                },
                {
                    value: lang.guaranteed[locale],
                    type: 'text',
                },
            ],
            child: [],
        },
        {
            cells: [
                {
                    value: '5',
                    type: 'text',
                },
                {
                    value: '50 000 wARTR ',
                    type: 'text',
                },
                {
                    value: 'X2.5',
                    type: 'text',
                },
                {
                    value: lang.guaranteed[locale],
                    type: 'text',
                },
            ],
            child: [],
        },
        {
            cells: [
                {
                    value: '6',
                    type: 'text',
                },
                {
                    value: '100 000 wARTR ',
                    type: 'text',
                },
                {
                    value: 'X5',
                    type: 'text',
                },
                {
                    value: lang.guaranteed[locale],
                    type: 'text',
                },
            ],
            child: [],
        },
        {
            cells: [
                {
                    value: '7',
                    type: 'text',
                },
                {
                    value: '200 000 wARTR ',
                    type: 'text',
                },
                {
                    value: 'X11',
                    type: 'text',
                },
                {
                    value: lang.guaranteed[locale],
                    type: 'text',
                },
            ],
            child: [],
        },
        {
            cells: [
                {
                    value: '8',
                    type: 'text',
                },
                {
                    value: '500 000 wARTR ',
                    type: 'text',
                },
                {
                    value: 'X28',
                    type: 'text',
                },
                {
                    value: lang.guaranteed[locale],
                    type: 'text',
                },
            ],
            child: [],
        },
        {
            cells: [
                {
                    value: '9',
                    type: 'text',
                },
                {
                    value: '1000 000 wARTR ',
                    type: 'text',
                },
                {
                    value: 'X36',
                    type: 'text',
                },
                {
                    value: lang.guaranteed[locale],
                    type: 'text',
                },
            ],
            child: [],
        },
    ];

    const table2Rows = [
        {
            cells: [
                {
                    value: lang['1m'][locale],
                    type: 'text',
                },
                {
                    value: 'wARTR ',
                    type: 'text',
                },
                {
                    value: '0%',
                    type: 'text',
                },
                {
                    value: 'X1',
                    type: 'text',
                },
            ],
            child: [],
        },
        {
            cells: [
                {
                    value: lang['4m'][locale],
                    type: 'text',
                },
                {
                    value: 'wARTR ',
                    type: 'text',
                },
                {
                    value: '12%',
                    type: 'text',
                },
                {
                    value: 'X1',
                    type: 'text',
                },
            ],
            child: [],
        },
        {
            cells: [
                {
                    value: lang['6m'][locale],
                    type: 'text',
                },
                {
                    value: 'wARTR ',
                    type: 'text',
                },
                {
                    value: '18%',
                    type: 'text',
                },
                {
                    value: 'X2',
                    type: 'text',
                },
            ],
            child: [],
        },
        {
            cells: [
                {
                    value: lang['12m'][locale],
                    type: 'text',
                },
                {
                    value: 'wARTR ',
                    type: 'text',
                },
                {
                    value: '24%',
                    type: 'text',
                },
                {
                    value: 'X3',
                    type: 'text',
                },
            ],
            child: [],
        },
    ];

    const toolTip = (e, i) => {
        const rect = e.target.getClientRects()[0];
        const content = [
            (props) => (
                <>
                    <p className={levels__toolTipText} {...props}>
                        {lang.apyInfo[locale]}
                    </p>
                </>
            ),
            (props) => (
                <>
                    <p className={levels__toolTipText} {...props}>
                        {lang.columnInfo[locale]}
                    </p>
                </>
            ),
        ];

        setToolTip({
            content: content[i],
            x: rect.x + (rect.x < window.innerWidth / 2 ? 0 : rect.height),
            y: window.scrollY + rect.y + rect.height,
        });
    };
    return (
        <div className={levels}>
            <div className={levels__top}>
                <div className={levels__block}>
                    <div className={levels__blockTitle}>
                        <h2 className={levels__blockTitleText}>{lang.numOfSteak[locale]}:</h2>
                    </div>

                    <p className={levels__blockText + ' ' + levels__blockText_accent}>
                        {initState.main_header.number_of_stakers.toLocaleString(locale)}
                    </p>
                </div>

                <div className={levels__block}>
                    <div className={levels__blockTitle}>
                        <h2 className={levels__blockTitleText}>{lang.totalArtr[locale]}:</h2>
                    </div>

                    <p className={levels__blockText + ' ' + levels__blockText_accent}>
                        {initState.main_header.total_artr_staked.toLocaleString(locale)}
                    </p>
                </div>

                <div className={levels__block}>
                    <div className={levels__blockTitle}>
                        <h2 className={levels__blockTitleText}>{lang.apy[locale]}:</h2>
                        <InfoIcon
                            className={levels__blockTitleToolTip}
                            onClick={(e) => toolTip(e, 0)}
                        />
                    </div>

                    <p className={levels__blockText + ' ' + levels__blockText_accent}>
                        {initState.main_header.apy.toLocaleString(locale)} %
                    </p>
                </div>
            </div>

            {/* <h2 className={levels__header}>{lang.artrLvl[locale]}</h2> */}
            <h2 className={levels__header}>{initState.main_block_1.name_1}</h2>

            <div className={levels__block}>
            {
                initState.main_block_1.text_1.split('<br/>').map((el, i) => {
                    console.log(el);
                    return (
                        <p className={levels__blockText}>{el.trim()}</p>
                    )
                })
            }
            </div>

            <Table
                className={levels__table}
                headers={[
                    {
                        name: initState.main_block_1.table_1.th_header.th_1,
                        toolTip: null,
                        width: '1fr',
                    },
                    {
                        name: initState.main_block_1.table_1.th_header.th_2,
                        toolTip: (e) => toolTip(e, 1),
                        width: '1fr',
                    },
                    {
                        name: initState.main_block_1.table_1.th_header.th_3,
                        toolTip: (e) => toolTip(e, 1),
                        width: '1fr',
                    },
                    {
                        name: initState.main_block_1.table_1.th_header.th_4,
                        toolTip: (e) => toolTip(e, 1),
                        width: '1fr',
                    },
                ]}
                rows={table1Rows}
                easyTable={true}
                easyRows={[
                    initState.main_block_1.table_1.td_1,
                    initState.main_block_1.table_1.td_2,
                    initState.main_block_1.table_1.td_3,
                    initState.main_block_1.table_1.td_4,
                    initState.main_block_1.table_1.td_5,
                    initState.main_block_1.table_1.td_6,
                    initState.main_block_1.table_1.td_7,
                    initState.main_block_1.table_1.td_8,
                    initState.main_block_1.table_1.td_9,
                ]}
            />

            <h2 className={levels__header}>{initState.main_block_2.name_2}</h2>

            <div className={levels__block}>
            {
                initState.main_block_2.text_2.split('<br/>').map((el, i) => {
                    console.log(el);
                    return (
                        <p className={levels__blockText}>{el.trim()}</p>
                    )
                })
            }
            </div>

            <Table
                className={levels__table}
                style={{ marginBottom: '48px' }}
                headers={[
                    {
                        name: initState.main_block_2.table_2.th_header.th_1,
                        width: '1fr',
                        toolTip: null,
                    },
                    {
                        name: initState.main_block_2.table_2.th_header.th_2,
                        width: '1fr',
                        toolTip: null,
                    },
                    {
                        name: initState.main_block_2.table_2.th_header.th_3,
                        width: '1fr',
                        toolTip: (e) => toolTip(e, 1),
                    },
                    {
                        name: initState.main_block_2.table_2.th_header.th_4,
                        width: '1fr',
                        toolTip: (e) => toolTip(e, 1),
                    },
                ]}
                rows={table2Rows}
                easyTable={true}
                easyRows={[
                    initState.main_block_2.table_2.td_1,
                    initState.main_block_2.table_2.td_2,
                    initState.main_block_2.table_2.td_3,
                    initState.main_block_2.table_2.td_4,
                ]}
            />

            <div className={levels__block}>
            {
                initState.main_block_3.text_3.split('<br/>').map((el, i) => {
                    console.log(el);
                    return (
                        <p className={levels__blockText}>{el.trim()}</p>
                    )
                })
            }
            </div>

            <h2 className={levels__header}>{initState.main_block_4.name_4}</h2>

            <div className={levels__block}>
            {
                initState.main_block_4.text_4.split('<br/>').map((el, i) => {
                    console.log(el);
                    return (
                        <p className={levels__blockText}>{el.trim()}</p>
                    )
                })
            }
            </div>

            <Table
                className={levels__table}
                headers={[
                    {
                        name: initState.main_block_4.table_4.th_header.th_1,
                        toolTip: null,
                        width: '1fr',
                    },
                    {
                        name: initState.main_block_4.table_4.th_header.th_2,
                        toolTip: null,
                        width: '1fr',
                    },
                    {
                        name: initState.main_block_4.table_4.th_header.th_3,
                        toolTip: null,
                        width: '1fr',
                    },
                    {
                        name: initState.main_block_4.table_4.th_header.th_4,
                        toolTip: null,
                        width: '1fr',
                    },
                ]}
                easyTable={true}
                easyRows={[
                    initState.main_block_4.table_4.td_1,
                ]}
            />

            <div className={levels__block}>
            {
                initState.main_block_5.text_5.split('<br/>').map((el, i) => {
                    console.log(el);
                    return (
                        <p className={levels__blockText}>{el.trim()}</p>
                    )
                })
            }
            </div>
        </div>
    );
};

export default LevelsPage;
