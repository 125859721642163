const SearchIcon = (props) => (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M11.2667 1.73334C6.00166 1.73334 1.73333 6.00167 1.73333 11.2667C1.73333 16.5317 6.00166 20.8 11.2667 20.8C16.5317 20.8 20.8 16.5317 20.8 11.2667C20.8 6.00167 16.5317 1.73334 11.2667 1.73334ZM11.2667 17.3333C7.91613 17.3333 5.2 14.6172 5.2 11.2667C5.2 7.91614 7.91613 5.2 11.2667 5.2C14.6172 5.2 17.3333 7.91614 17.3333 11.2667C17.3333 14.6172 14.6172 17.3333 11.2667 17.3333Z"
            fill="white" fill-opacity="0.5"></path>
        <path
            d="M24.1926 21.7412L20.3636 17.9118C19.6772 18.8496 18.85 19.6768 17.9123 20.3632L21.7412 24.1921C22.0549 24.5063 22.4883 24.7 22.9667 24.7C23.9239 24.7 24.7 23.9239 24.7 22.9667C24.7 22.4883 24.5059 22.0549 24.1926 21.7412Z"
            fill="white" fill-opacity="0.5"></path>
    </svg>
);

export default SearchIcon;