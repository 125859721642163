export const calcProsent = (num1, num2) => {
    if (!num1 || !num2) {
        return 'Loading...';
    }
    const raz = (num2 - num1).toFixed(5);
    if (num2 != 0 && num1 != 0) {
        const p = ((num2 / num1 - 1) * 100).toFixed(3);
        return `${raz > 0 ? '+' + raz : raz} (${p > 0 ? '+' + p : p}%)`;
    }
    return `${raz > 0 ? '+' + raz : raz}`;
};
