const InfoIcon = (props) => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g clip-path="url(#clip0_847_7211)">
            <path
                d="M18 9C18 13.9705 13.9705 18 9 18C4.02944 18 0 13.9705 0 9C0 4.02944 4.02944 0 9 0C13.9705 0 18 4.02944 18 9ZM1.84736 9C1.84736 12.9503 5.04971 16.1526 9 16.1526C12.9503 16.1526 16.1526 12.9503 16.1526 9C16.1526 5.04971 12.9503 1.84736 9 1.84736C5.04971 1.84736 1.84736 5.04971 1.84736 9Z"
                fill="url(#paint0_linear_847_7211)" />
            <path
                d="M8.43886 13.0345C8.36438 13.0345 8.30107 13.0084 8.24893 12.9563C8.19679 12.9041 8.17072 12.8371 8.17072 12.7552V7.50412C8.17072 7.42219 8.19679 7.35516 8.24893 7.30302C8.30107 7.25088 8.36438 7.22481 8.43886 7.22481H9.5561C9.63803 7.22481 9.70507 7.25088 9.75721 7.30302C9.80934 7.35516 9.83541 7.42219 9.83541 7.50412V12.7552C9.83541 12.8371 9.80934 12.9041 9.75721 12.9563C9.70507 13.0084 9.63803 13.0345 9.5561 13.0345H8.43886ZM8.40535 6.33102C8.33086 6.33102 8.26755 6.30495 8.21541 6.25281C8.16328 6.20067 8.13721 6.13364 8.13721 6.05171V5.15791C8.13721 5.07598 8.16328 5.00895 8.21541 4.95681C8.26755 4.89722 8.33086 4.86743 8.40535 4.86743H9.57845C9.66038 4.86743 9.72741 4.89722 9.77955 4.95681C9.83914 5.00895 9.86893 5.07598 9.86893 5.15791V6.05171C9.86893 6.13364 9.83914 6.20067 9.77955 6.25281C9.72741 6.30495 9.66038 6.33102 9.57845 6.33102H8.40535Z"
                fill="url(#paint1_linear_847_7211)" />
        </g>
        <defs>
            <linearGradient id="paint0_linear_847_7211" x1="2.53846" y1="4.92453" x2="52.6154" y2="4.92453"
                gradientUnits="userSpaceOnUse">
                <stop stop-color="#CD3ED0" />
                <stop offset="0.46875" stop-color="#6B1495" />
            </linearGradient>
            <linearGradient id="paint1_linear_847_7211" x1="8.38142" y1="7.10181" x2="13.1992" y2="7.10181"
                gradientUnits="userSpaceOnUse">
                <stop stop-color="#CD3ED0" />
                <stop offset="0.46875" stop-color="#6B1495" />
            </linearGradient>
            <clipPath id="clip0_847_7211">
                <rect width="18" height="18" fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export default InfoIcon;