function Error(props) {
   return (
      <svg
         {...props}
         width={props.width ? props.width : "40"}
         height={props.height ? props.height : "40"}
         viewBox="0 0 40 40"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
      >
         <path d="M22.5052 19.9956L19.9998 17.4894L17.4945 19.9956L19.9998 22.5018L22.5052 19.9956Z" fill="#040606" />
         <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22.5052 19.9956L19.9998 17.4894L28.2335 9.25297C28.9254 8.5609 30.0471 8.5609 30.7389 9.25297C31.4307 9.94503 31.4307 11.0671 30.7389 11.7592L22.5052 19.9956ZM19.9998 22.5018L28.2418 30.7465C28.9336 31.4385 30.0553 31.4385 30.7472 30.7465C31.439 30.0544 31.439 28.9323 30.7472 28.2403L22.5052 19.9956L19.9998 22.5018ZM17.4945 19.9956L9.2608 11.7592C8.56896 11.0671 8.56896 9.94503 9.2608 9.25297C9.95264 8.5609 11.0743 8.5609 11.7662 9.25297L19.9998 17.4894L17.4945 19.9956ZM17.4945 19.9956L9.25253 28.2403C8.56069 28.9323 8.56069 30.0544 9.25253 30.7465C9.94437 31.4385 11.0661 31.4385 11.7579 30.7465L19.9998 22.5018L17.4945 19.9956Z"
            fill="#040606"
         />
         <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M40 20C40 31.0457 31.0457 40 20 40C8.9543 40 0 31.0457 0 20C0 8.9543 8.9543 0 20 0C31.0457 0 40 8.9543 40 20ZM30.7472 30.7465C30.0553 31.4385 28.9336 31.4385 28.2418 30.7465L19.9998 22.5018L11.7579 30.7465C11.0661 31.4385 9.94437 31.4385 9.25253 30.7465C8.56069 30.0544 8.56069 28.9323 9.25253 28.2403L17.4945 19.9956L9.2608 11.7592C8.56896 11.0671 8.56896 9.94503 9.2608 9.25297C9.95264 8.5609 11.0743 8.5609 11.7662 9.25297L19.9998 17.4894L28.2335 9.25297C28.9254 8.5609 30.0471 8.5609 30.7389 9.25297C31.4307 9.94503 31.4307 11.0671 30.7389 11.7592L22.5052 19.9956L30.7472 28.2403C31.439 28.9323 31.439 30.0544 30.7472 30.7465Z"
            fill="#535353"
         />
      </svg>
   );
}

export default Error;
