import axios from 'axios';
import { getCookie } from '../../helpers/getCookie';

const intiStatePoints = {
    h: [],
    d: [],
    w: [],
    m: [],
};

export const getPoints = async () => {
    const cookiePoints = getCookie('cookiePoints');
    if (cookiePoints) {
        return JSON.parse(cookiePoints);
    }
    let domain = document.domain;
    if (domain === 'localhost') {
        domain = 'artpad.site';
    }
    let { data } = await axios.get(`https://${domain}/api2.php?parser=4`);
    intiStatePoints.h = data['h'].map((el) => el.data[0].price);
    intiStatePoints.d = data['d'].map((el) => el.data[0].price);
    intiStatePoints.w = data['w'].map((el) => el.data[0].price);
    intiStatePoints.m = data['m'].map((el) => el.data[0].price);
    document.cookie = 'cookiePoints=' + JSON.stringify(intiStatePoints) + '; max-age=300';
    return intiStatePoints;
};
