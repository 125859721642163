export default function Logo (props) {
    return (
        <svg width="179" height="61" viewBox="0 0 179 61" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} >
            <path
                d="M133.122 42.9428C132.934 42.9428 132.781 42.7899 132.781 42.6014V20.4135C132.781 18.5283 134.309 17 136.194 17H153.355C154.34 17 155.228 17.2402 156.021 17.7206C156.838 18.2011 157.486 18.8496 157.967 19.6663C158.471 20.459 158.724 21.3478 158.724 22.3327V37.6101C158.724 38.5709 158.471 39.4597 157.967 40.2764C157.486 41.0931 156.838 41.7417 156.021 42.2221C155.228 42.7025 154.34 42.9428 153.355 42.9428H133.122ZM138.33 37.3218H153.067V22.6209H138.33V37.3218Z"
                fill="white" />
            <path
                d="M71.4795 42.4531C71.291 42.4531 71.1382 42.3003 71.1382 42.1118V20.4941C71.1382 18.6089 72.6665 17.0806 74.5517 17.0806H91.26C92.2233 17.0806 93.0925 17.3158 93.8678 17.7863C94.6665 18.2569 95.3009 18.8921 95.7707 19.6919C96.2641 20.4918 96.5108 21.3623 96.5108 22.3033V28.867C96.5108 29.808 96.2641 30.6785 95.7707 31.4784C95.3009 32.2782 94.6665 32.9134 93.8678 33.3839C93.0925 33.8545 92.2233 34.0897 91.26 34.0897L76.6003 34.125V42.1118C76.6003 42.3003 76.4475 42.4531 76.259 42.4531H71.4795ZM76.6003 28.5847H90.9781V22.5503H76.6003V28.5847Z"
                fill="white" />
            <path
                d="M101.944 42.9319C101.756 42.9319 101.603 42.779 101.603 42.5905V22.3944C101.603 21.4131 101.842 20.5274 102.321 19.7375C102.8 18.9237 103.446 18.2774 104.26 17.7987C105.074 17.3199 105.959 17.0806 106.917 17.0806H122.104C123.086 17.0806 123.971 17.3199 124.761 17.7987C125.575 18.2774 126.221 18.9237 126.7 19.7375C127.203 20.5274 127.454 21.4131 127.454 22.3944V42.5905C127.454 42.779 127.301 42.9319 127.113 42.9319H122.158C121.97 42.9319 121.817 42.779 121.817 42.5905V34.602H107.168V42.5905C107.168 42.779 107.015 42.9319 106.827 42.9319H101.944ZM107.168 29.0009H121.817V22.6817H107.168V29.0009Z"
                fill="white" />
            <path fillRule="evenodd" clipRule="evenodd"
                d="M31.2206 24.9652C31.2204 25.1115 31.1426 25.2465 31.0165 25.3197L26.2886 28.0596C26.1622 28.1328 26.0065 28.1328 25.8801 28.0596L5.02006 15.9673C3.43835 15.0504 3.43835 12.7591 5.02006 11.8422L24.8979 0.319354C25.632 -0.106228 26.5366 -0.106466 27.271 0.318729L35.8108 5.26314C35.9372 5.33632 36.0151 5.47165 36.0151 5.6181V7.98166H40.3679C40.4394 7.98166 40.5096 8.00049 40.5716 8.03627L50.0607 13.5162C50.1871 13.5892 50.2651 13.7242 50.2654 13.8706C50.2656 14.0169 50.1881 14.1523 50.062 14.2258L45.3008 16.9991C45.1744 17.0728 45.0183 17.073 44.8917 16.9996L37.4285 12.6773H33.7186L22.1044 19.4187L31.0172 24.6104C31.1433 24.6838 31.2208 24.819 31.2206 24.9652ZM12.5859 13.9033L16.5278 16.1718L28.4395 9.25773V7.45978L26.0523 6.09076L12.5859 13.9033ZM44.5068 51.5055C44.6381 51.4293 44.7189 51.2889 44.7189 51.1371V28.4585L54.4405 22.8159C54.5718 22.7397 54.6526 22.5993 54.6526 22.4475V18.4922C54.6526 17.3751 53.4433 16.6771 52.476 17.2358L30.204 30.1006C29.4382 30.5429 28.9665 31.3602 28.9665 32.2445V36.7923C28.9665 36.9444 29.0476 37.085 29.1794 37.1611C29.3112 37.2372 29.4735 37.2373 29.6053 37.1612L38.9001 31.7988V54.0199C38.9001 54.1723 38.9815 54.3131 39.1136 54.3891C39.2456 54.4651 39.4082 54.4648 39.54 54.3884L44.5068 51.5055ZM5.8188 48.8177V38.0593L19.8674 46.1806V57.3968C19.8674 57.5486 19.9482 57.689 20.0795 57.7652L25.0464 60.648C25.1781 60.7245 25.3407 60.7248 25.4728 60.6488C25.6048 60.5728 25.6862 60.432 25.6862 60.2796V32.7733C25.6862 31.8889 25.2145 31.0717 24.4487 30.6293L3.71431 18.6527C2.0637 17.6993 0 18.8905 0 20.7966V45.9348C0 46.0867 0.0808211 46.227 0.212141 46.3032L5.17899 49.1861C5.31076 49.2626 5.47335 49.2629 5.60539 49.1868C5.73742 49.1108 5.8188 48.97 5.8188 48.8177ZM5.8188 31.3442V26.5973L19.8674 34.7187V39.4656L5.8188 31.3442Z"
                fill="url(#paint0_linear_328_557)" />
            <defs>
                <linearGradient id="paint0_linear_328_557" x1="50.6913" y1="92.4371" x2="16.8261" y2="-10.6494"
                    gradientUnits="userSpaceOnUse">
                    <stop offset="0.395833" stopColor="#BE3DB1" />
                    <stop offset="0.726585" stopColor="#6D1E9F" />
                </linearGradient>
            </defs>
        </svg>
    )
}