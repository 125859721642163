
const LocaleIcon = (props) => {
    return (
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M9.41675 16.4567C9.91466 20.2471 11.1076 22.9167 12.5001 22.9167C13.8926 22.9167 15.0855 20.2471 15.5834 16.4567C14.6063 16.3229 13.5717 16.25 12.5001 16.25C11.4284 16.25 10.3938 16.3229 9.41675 16.4567Z" fill="white">
            </path>
            <path d="M9.24883 10.2062C9.1955 10.945 9.16675 11.7117 9.16675 12.5C9.16675 13.2883 9.1955 14.055 9.24883 14.7937C10.2788 14.6562 11.3672 14.5833 12.5001 14.5833C13.633 14.5833 14.7213 14.6562 15.7513 14.7937C15.8047 14.055 15.8334 13.2883 15.8334 12.5C15.8334 11.7117 15.8047 10.945 15.7513 10.2062C14.7213 10.3437 13.633 10.4167 12.5001 10.4167C11.3672 10.4167 10.2788 10.3437 9.24883 10.2062Z" fill="white">
            </path>
            <path d="M15.5266 22.4683C17.8662 21.7588 19.8566 20.2496 21.1799 18.2579C20.147 17.6208 18.7924 17.1013 17.2274 16.7504C16.9249 19.0067 16.3654 21.0392 15.5266 22.4683Z" fill="white">
            </path>
            <path d="M9.4734 22.4683C8.63423 21.0392 8.07507 19.0067 7.77257 16.75C6.20757 17.1008 4.85257 17.6204 3.82007 18.2575C5.14382 20.2496 7.13382 21.7592 9.4734 22.4683Z" fill="white">
            </path>
            <path d="M9.4734 2.53168C7.13382 3.24126 5.1434 4.75043 3.82007 6.74209C4.85299 7.37918 6.20757 7.89876 7.77257 8.24959C8.07507 5.99334 8.63465 3.96084 9.4734 2.53168Z" fill="white">
            </path>
            <path d="M3.01909 16.8138C4.25034 16.0592 5.80659 15.4638 7.59784 15.0792C7.53325 14.23 7.49992 13.3646 7.49992 12.5C7.49992 11.6354 7.53325 10.77 7.59784 9.92126C5.80659 9.53668 4.25034 8.94084 3.01909 8.18668C2.41992 9.50126 2.08325 10.9608 2.08325 12.5C2.08325 14.0392 2.41992 15.4988 3.01909 16.8138Z" fill="white">
            </path>
            <path d="M12.5001 2.08334C11.1076 2.08334 9.91466 4.75293 9.41675 8.54334C10.3938 8.67709 11.4284 8.75001 12.5001 8.75001C13.5717 8.75001 14.6063 8.67709 15.5834 8.54334C15.0855 4.75293 13.8926 2.08334 12.5001 2.08334Z" fill="white">
            </path>
            <path d="M21.9809 8.18625C20.7496 8.94083 19.1933 9.53625 17.4021 9.92083C17.4667 10.77 17.5 11.6354 17.5 12.5C17.5 13.3646 17.4667 14.23 17.4021 15.0787C19.1933 15.4633 20.7496 16.0592 21.9809 16.8133C22.58 15.4987 22.9167 14.0392 22.9167 12.5C22.9167 10.9608 22.58 9.50125 21.9809 8.18625Z" fill="white">
            </path>
            <path d="M15.5266 2.53168C16.3658 3.96084 16.9249 5.99334 17.2274 8.25001C18.7924 7.89918 20.1474 7.37959 21.1799 6.74251C19.8562 4.75043 17.8662 3.24084 15.5266 2.53168Z" fill="white">
            </path>
        </svg>
    );
}

export default LocaleIcon;